// Generated by Framer (aab6bf9)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Image, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";

const cycleOrder = ["o4gLKEBAf", "XnNod6pXV", "VEMT183Rn", "btLBvTWlI", "vac8e9cx_"];

const serializationHash = "framer-MNa67"

const variantClassNames = {btLBvTWlI: "framer-v-zdy7rf", o4gLKEBAf: "framer-v-1ghtff0", vac8e9cx_: "framer-v-1il26rk", VEMT183Rn: "framer-v-7bkar2", XnNod6pXV: "framer-v-1p5aouv"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const toResponsiveImage = (value) => {
    if (typeof value === "object" && value !== null && typeof value.src === "string") {
        return value;
    };
    return typeof value === "string" ? {src: value} : undefined;
};


const transition1 = {damping: 60, delay: 0, mass: 1, stiffness: 500, type: "spring"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "o4gLKEBAf", "Variant 2": "XnNod6pXV", "Variant 3": "VEMT183Rn", "Variant 4": "btLBvTWlI", "Variant 5": "vac8e9cx_"}

const getProps = ({height, id, image, image2, image3, image4, image5, width, ...props}) => { return {...props, cgc_gCEl3: image4 ?? props.cgc_gCEl3, GddE17S7H: image ?? props.GddE17S7H, LgXKHU65A: image3 ?? props.LgXKHU65A, P4Rr0Qzcp: image2 ?? props.P4Rr0Qzcp, r2GurcdFA: image5 ?? props.r2GurcdFA, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "o4gLKEBAf"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap;image?: {src: string; srcSet?: string};image2?: {src: string; srcSet?: string};image3?: {src: string; srcSet?: string};image4?: {src: string; srcSet?: string};image5?: {src: string; srcSet?: string}; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, GddE17S7H, P4Rr0Qzcp, LgXKHU65A, cgc_gCEl3, r2GurcdFA, ...restProps} = getProps(props)

const {baseVariant, classNames, gestureHandlers, gestureVariant, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "o4gLKEBAf", variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><Image {...restProps} {...gestureHandlers} background={{alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(GddE17S7H)}} className={cx(serializationHash, ...sharedStyleClassNames, "framer-1ghtff0", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"o4gLKEBAf"} ref={ref ?? ref1} style={{borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} {...addPropertyOverrides({btLBvTWlI: {"data-framer-name": "Variant 4", background: {alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(cgc_gCEl3)}}, vac8e9cx_: {"data-framer-name": "Variant 5", background: {alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(r2GurcdFA)}}, VEMT183Rn: {"data-framer-name": "Variant 3", background: {alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(LgXKHU65A)}}, XnNod6pXV: {"data-framer-name": "Variant 2", background: {alt: "", fit: "fill", sizes: componentViewport?.width || "100vw", ...toResponsiveImage(P4Rr0Qzcp)}}}, baseVariant, gestureVariant)}/></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-MNa67.framer-1v0986m, .framer-MNa67 .framer-1v0986m { display: block; }", ".framer-MNa67.framer-1ghtff0 { height: 649px; overflow: hidden; position: relative; width: 457px; will-change: var(--framer-will-change-override, transform); }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 649
 * @framerIntrinsicWidth 457
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"XnNod6pXV":{"layout":["fixed","fixed"]},"VEMT183Rn":{"layout":["fixed","fixed"]},"btLBvTWlI":{"layout":["fixed","fixed"]},"vac8e9cx_":{"layout":["fixed","fixed"]}}}
 * @framerVariables {"GddE17S7H":"image","P4Rr0Qzcp":"image2","LgXKHU65A":"image3","cgc_gCEl3":"image4","r2GurcdFA":"image5"}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const FramerZUXC1mbJv: React.ComponentType<Props> = withCSS(Component, css, "framer-MNa67") as typeof Component;
export default FramerZUXC1mbJv;

FramerZUXC1mbJv.displayName = "Image";

FramerZUXC1mbJv.defaultProps = {height: 649, width: 457};

addPropertyControls(FramerZUXC1mbJv, {variant: {options: ["o4gLKEBAf", "XnNod6pXV", "VEMT183Rn", "btLBvTWlI", "vac8e9cx_"], optionTitles: ["Variant 1", "Variant 2", "Variant 3", "Variant 4", "Variant 5"], title: "Variant", type: ControlType.Enum}, GddE17S7H: {title: "Image", type: ControlType.ResponsiveImage}, P4Rr0Qzcp: {title: "Image 2", type: ControlType.ResponsiveImage}, LgXKHU65A: {title: "Image 3", type: ControlType.ResponsiveImage}, cgc_gCEl3: {title: "Image 4", type: ControlType.ResponsiveImage}, r2GurcdFA: {title: "Image 5", type: ControlType.ResponsiveImage}} as any)

addFonts(FramerZUXC1mbJv, [{explicitInter: true, fonts: []}], {supportsExplicitInterCodegen: true})